import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import PostForm from "../PostForm/PostForm";
import { PostType } from "../types/post";
import { JsonBoxURL } from "../utils";

interface EditPostProps extends PostType, RouteComponentProps {
	refreshList: () => void;
}

function EditPost(props: EditPostProps) {
	const [title, setTitle] = useState(props.title);
	const [body, setBody] = useState(props.body);

	async function onSubmit() {
		await fetch(`${JsonBoxURL}/${props._id}`, {
			method: "PUT",
			headers: { "content-type": "application/json" },
			body: JSON.stringify({ title, body }),
		});
		props.refreshList();
		props.history.push(`/post/${props._id}`);
	}

	return <PostForm {...{ title, body, setTitle, setBody, onSubmit }} />;
}

export default withRouter(EditPost);
