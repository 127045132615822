export const JsonBoxId = "adelios_bugs_search_engine";
export const JsonBoxURL = `https://jsonbox.adelios.fr/${JsonBoxId}`;

export function truncate(text: string, length: number) {
    return text.length > length ? text.substring(0, length) + "..." : text;
}

export function formatDate(date: Date) {
    return date.toLocaleDateString("fr-FR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    });
}
