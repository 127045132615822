import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import styles from "./Pagination.module.scss";

function Pagination({ length, index }: any) {
  const { search, pathname } = useLocation();
  const pagesCount = Math.round(length / 10);
  const lists = [...new Array(pagesCount)];

  const searchParams = new URLSearchParams(search);
  const activePage = +(searchParams.get("page") || 1);

  function getPage(page: number) {
    if (page <= 0 || page > pagesCount) {
      return pathname + search;
    }
    searchParams.set("page", page.toString());
    return pathname + "?" + searchParams;
  }

  function createDefaultList() {
    for (let i = 1; i <= (pagesCount > 5 ? 5 : pagesCount); i++) {
      lists.push(
        <li key={i}>
          <NavLink
            to={getPage(i)}
            className={[i === index && styles.activeIndex]
              .filter(Boolean)
              .join(" ")}
          >
            {i}
          </NavLink>
        </li>
      );
    }
  }

  function createEndList() {
    for (let i = pagesCount - 4; i <= pagesCount; i++) {
      lists.push(
        <li key={i}>
          <NavLink
            to={getPage(i)}
            className={[i === index && styles.activeIndex]
              .filter(Boolean)
              .join(" ")}
          >
            {i}
          </NavLink>
        </li>
      );
    }
  }

  function createList() {
    for (
      let i = index - 2;
      i <= (index + 2 > pagesCount ? pagesCount : index + 2);
      i++
    ) {
      lists.push(
        <li key={i}>
          <NavLink
            to={getPage(i)}
            className={[i === index && styles.activeIndex]
              .filter(Boolean)
              .join(" ")}
          >
            {i}
          </NavLink>
        </li>
      );
    }
  }

  if (pagesCount === 1) return null;
  else if (index >= pagesCount - 2 && index >= 5) createEndList();
  else if (index >= 4 && pagesCount > 5) createList();
  else createDefaultList();

  return (
    <div className={styles.wrapper}>
      <div className={styles.center}>
        <Link
          className={[styles.min, index < 4 && styles.hidden]
            .filter(Boolean)
            .join(" ")}
          to={getPage(1)}
        >
          1
        </Link>
        <span
          className={[styles.point, index < 4 && styles.hidden]
            .filter(Boolean)
            .join(" ")}
        >
          ...
        </span>
        <ul className={styles.listes}>{lists}</ul>
        <span
          className={[styles.point, index >= pagesCount - 2 && styles.hidden]
            .filter(Boolean)
            .join(" ")}
        >
          ...
        </span>
        <Link
          className={[styles.max, index >= pagesCount - 2 && styles.hidden]
            .filter(Boolean)
            .join(" ")}
          to={getPage(pagesCount)}
        >
          {pagesCount}
        </Link>
      </div>
      <Link
        className={[styles.btnPrev, index === 1 && styles.filter]
          .filter(Boolean)
          .join(" ")}
        to={getPage(activePage - 1)}
      ></Link>
      <Link
        className={[styles.btnNext, pagesCount === index && styles.filter]
          .filter(Boolean)
          .join(" ")}
        to={getPage(activePage + 1)}
      ></Link>
    </div>
  );
}

export default Pagination;
