import React, { useState } from "react";
import styles from "./Back.module.scss";
import { Link, Redirect } from "react-router-dom";

function Back({ edit, setRetour }: any) {
  const [redirect, setRedirect] = useState(false);
  const openPopIn = () => {
    setRetour(true);
    var res = window.confirm("Retour ? T'es sur de toi ? Vraiment ?");
    if (res === true) {
      setRedirect(true);
    }
  };

  if (redirect) return <Redirect to="/" />;
  else if (edit) {
    return (
      <button className={styles.wrapper} onClick={openPopIn}>
        Retour
      </button>
    );
  }
  return (
    <Link to="/" className={styles.wrapper}>
      Retour
    </Link>
  );
}

export default Back;
