import React, { useState } from "react";
import styles from "./PostForm.module.scss";
import Back from "../Back/Back";
import PostBody from "../PostBody/PostBody";

interface PostFormProps {
  title: string;
  body: string;
  setTitle: (title: string) => void;
  setBody: (body: string) => void;
  onSubmit: () => void;
}

function PostForm({ body, onSubmit, setBody, setTitle, title }: PostFormProps) {
  const [retour, setRetour] = useState(false);
  return (
    <form
      className={styles.wrapper}
      onSubmit={(e) => {
        e.preventDefault();
        console.log(retour);

        if (!retour) onSubmit();
      }}
    >
      <Back edit={true} setRetour={setRetour} />

      <h2 className={styles.title}>Ajouter un post</h2>

      <label className={styles.label}>
        <p>Titre :</p>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          autoFocus
          required
        />
      </label>

      <label className={styles.label}>
        <p>Contenu :</p>
        <textarea
          value={body}
          onChange={(e) => setBody(e.target.value)}
          required
        />
      </label>

      <button
        type="submit"
        onClick={() => setRetour(false)}
        className={styles.submit}
      >
        Valider
      </button>

      {!!body.length && <p className={styles.preview}>Preview :</p>}
      <PostBody>{body}</PostBody>
    </form>
  );
}

export default PostForm;
