import React from 'react';
import marked from 'marked';

interface PostBodyProps {
	children: string;
}

function PostBody({ children }: PostBodyProps) {
	return (
		<div
			className="markdown-body"
			dangerouslySetInnerHTML={{ __html: marked(children) }}
		/>
	);
}

export default PostBody;
