import React from "react";
import { withRouter } from "react-router";
import { JsonBoxURL } from "../utils";
import styles from "./DeletePost.module.scss";

function DeletePost({ history, id, refreshList }: any) {
	function popIn() {
		var res = window.confirm(
			"Supprimer ? T'es sur de toi ? Vraiment ? Vraiment ? Vraiment ? Vraiment ? Vraiment ? Vraiment ?"
		);
		if (res === true) {
			onSubmit();
		}
	}

	async function onSubmit() {
		await fetch(`${JsonBoxURL}/${id}`, {
			method: "DELETE",
			headers: { "content-type": "application/json" },
		});
		history.push("/");
		refreshList();
	}

	return (
		<button className={styles.btn} onClick={popIn}>
			Supprimer
		</button>
	);
}

export default withRouter(DeletePost);
