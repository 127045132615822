import React from "react";
import { Link } from "react-router-dom";
import { PostType } from "../types/post";
import { formatDate, truncate } from "../utils";
import styles from "./List.module.scss";

interface ListProps {
	posts: PostType[];
}

function List({ posts }: ListProps) {
	return (
		<>
			{posts.map(({ title, _createdOn, _id, body }, i) => (
				<Link key={i} to={"/post/" + _id} className={styles.post}>
					<h2 className={styles.title}>{title}</h2>
					<p className={styles.date}>{formatDate(_createdOn)}</p>
					<p className={styles.body}>{truncate(body, 60)}</p>
				</Link>
			))}
		</>
	);
}

export default List;
