import React from "react";
import { Link } from "react-router-dom";
import Back from "../Back/Back";
import DeletePost from "../DeletePost/DeletePost";
import PostBody from "../PostBody/PostBody";
import { PostType } from "../types/post";
import { formatDate } from "../utils";
import styles from "./Post.module.scss";

function Post({ _id, title, body, _createdOn, refreshList }: PostType) {
	return (
		<div className={styles.wrapper}>
			<Back />
			<h2 className={styles.title}>
				{title}{" "}
				<Link to={`/edit/${_id}`} className={styles.edit}>
					Éditer
				</Link>
				<DeletePost id={_id} refreshList={refreshList} />
			</h2>

			<p className={styles.date}>{formatDate(_createdOn)}</p>
			<PostBody>{body}</PostBody>
		</div>
	);
}

export default Post;
