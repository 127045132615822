import React from "react";
import { JsonBoxURL } from "../utils";
import styles from "./Backup.module.scss";

function Backup() {
	async function onClick() {
		const response = await fetch(`${JsonBoxURL}?limit=1000`);
		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.style.display = "none";
		a.href = url;
		a.download = `google-adelios-${new Date().toLocaleDateString()}.json`;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
	}

	return (
		<button className={styles.button} onClick={onClick}>
			Backup
		</button>
	);
}

export default Backup;
