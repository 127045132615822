import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import PostForm from "../PostForm/PostForm";
import { JsonBoxURL } from "../utils";

interface AddPostProps extends RouteComponentProps {
	refreshList: () => void;
}

function AddPost({ history, refreshList }: AddPostProps) {
	const [title, setTitle] = useState("");
	const [body, setBody] = useState("");

	async function onSubmit() {
		await fetch(JsonBoxURL, {
			method: "POST",
			headers: { "content-type": "application/json" },
			body: JSON.stringify({ title, body }),
		});
		refreshList();
		history.push("/");
	}

	return <PostForm {...{ title, body, setTitle, setBody, onSubmit }} />;
}

export default withRouter(AddPost);
